import { createApp } from 'vue'
import App from './App.vue'
import './styles/styles.css'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

import router from './router'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBB8b76h0JwVs4Z_dmitWIcWJBzMpVGhoo",
    authDomain: "killian-website.firebaseapp.com",
    projectId: "killian-website",
    storageBucket: "killian-website.appspot.com",
    messagingSenderId: "715313599154",
    appId: "1:715313599154:web:2503826250ce0821e72fab"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore(firebaseApp)

createApp(App).use(router).mount('#app')