<template>
    <main v-if="heavenTeamFirst !== null" class="w-full relative flex justify-between items-center h-screen bg-neutral-800">

        <!-- GAUCHE -->
        <div v-if="heavenTeamFirst" class="transition duration-700 w-2/6 h-full flex items-center justify-start flex-col" :class="[{leftTeamClass: isLeftTeamTurn}, {leftTeamClass: finishedTeam}]">
            <h2 class="text-white font-bold mb-20 mt-10 text-3xl">TEAM HEAVEN</h2>
            <LeftSide :leftTeam="leftTeam" />
        </div>
        <div v-else class="transition duration-700 w-2/6 h-full flex items-center justify-start flex-col" :class="[{rightTeamClass: isLeftTeamTurn}, {rightTeamClass: finishedTeam}]">
            <h2 class="text-white font-bold mb-20 mt-10 text-3xl">TEAM HELL</h2>
            <LeftSide :leftTeam="leftTeam" />
        </div>

        <!-- MILIEU -->
        <div class="flex flex-col">
            <h2 v-if="finishedTeam" class="text-6xl text-center text-white uppercase pb-10 italic">fight !</h2>
            <div class="grid grid-cols-2 gap-x-10 gap-y-8 rounded-lg p-10">
                <button 
                    v-for="player in players"
                    :class="[{disabledButton: player.chosen}, {disabledButton: disableButton(player.nbr)}, {notChosable: buttonsDisable[player.nbr-1]}]"
                    :key="player.name"
                    :disabled="player.chosen || buttonsDisable[player.nbr-1] || disableButton(player.nbr)"
                    class="buttonPick transition duration-500 px-5 py-3 font-bold rounded-full text-white border-white border-2"
                    @click="namePick(player.nbr, player.name)">
                        {{ player.nbr }}: {{ player.name }} 
                </button>
            </div>
        </div>
        

        <!-- DROITE -->
        <div v-if="heavenTeamFirst" class="transition duration-700 w-2/6 h-full flex items-center justify-start flex-col" :class="[{rightTeamClass: !isLeftTeamTurn}, {rightTeamClass: finishedTeam}]">
            <h2 class="text-white font-bold mb-20 mt-10 text-3xl">TEAM HELL</h2>
            <RightSide :rightTeam="rightTeam" />
        </div>
        <div v-else class="transition duration-700 w-2/6 h-full flex items-center justify-start flex-col" :class="[{leftTeamClass: !isLeftTeamTurn}, {leftTeamClass: finishedTeam}]">
            <h2 class="text-white font-bold mb-20 mt-10 text-3xl">TEAM HEAVEN</h2>
            <RightSide :rightTeam="rightTeam" />
        </div>

    </main>
</template>

<style scoped>
    .leftTeamClass{
        background-color: rgb(13, 132, 230);
        opacity: 0.9;
    }
    .rightTeamClass{
        background-color: red;
        opacity: 0.9;
    }
    .buttonPick:hover{
        background-color: white;
        color:black;
    }
    .notChosable{
        border-color: grey;
        color: grey;
    }
    .notChosable:hover{
        background-color: transparent;
        color: grey;
        cursor: auto;
    }
    .disabledButton{
        text-decoration: line-through;
        text-decoration-thickness: 3px;
        background-color: white;
        color: black;
    }
    .disabledButton:hover{
        background-color: white;
        color: black;
    }
</style>

<script>
import LeftSide from "./LeftSide.vue"
import RightSide from "./RightSide.vue"
/* eslint-disable no-unused-vars */
import { doc, collection, getDocs, getDoc, updateDoc, setDoc, onSnapshot, query } from "firebase/firestore"
import {db} from '../main.js'

    export default{
        name: 'middle-picker',
        components: {
            LeftSide,
            RightSide
        },
        beforeMount(){
            this.getPlayers()
        },
        data(){
            return {
                players: [],
                chosablePlayers: [],
                leftTeam: [],
                rightTeam: [],
                buttonsDisable: [false, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                isLeftTeamTurn: true,
                finishedTeam: false,
                firstChoice: false,
                top1First: null,
                heavenTeamFirst: null
            }
        },
        methods: {
            async getPlayers(){
                const querySnapshot = await getDocs(collection(db, "joueurs"))
                querySnapshot.forEach((doc) => {
                    const playerData = doc.data()
                    this.players.push(playerData)
                    this.chosablePlayers.push(playerData)
                    if(playerData.chosen === true){
                        if(playerData.leftTeam){
                            this.leftTeam.push(playerData.name)
                            const playerIndex = this.chosablePlayers.indexOf(playerData)
                            this.chosablePlayers.splice(playerIndex, 1)
                        } else {
                            this.rightTeam.push(playerData.name)
                            const playerIndex = this.chosablePlayers.indexOf(playerData)
                            this.chosablePlayers.splice(playerIndex, 1)
                        }
                    }
                })
                this.players.sort((a,b) => {
                    return a.nbr - b.nbr
                })
                if(this.leftTeam.length === 0 && this.rightTeam.length === 0){
                    this.firstChoice = true
                } else if(this.leftTeam.length === 8 && this.rightTeam.length === 8){
                    this.finishedTeam = true
                } else {
                    const pickOrder = await getDoc(doc(db, "pickOrder", "firstPick"))
                    const res = pickOrder.data().top1First
                    if( res === true ){
                        this.top1First = true
                        this.top1Pick()
                    } else {
                        this.top1First = false
                        this.top2Pick()
                    }
                }
                const heavenFirst = await getDoc(doc(db, "teamFirst", "teamTurn"))
                this.heavenTeamFirst = heavenFirst.data().leftSide
            },
            namePick(id, name){
                this.isLeftTeamTurn ? this.leftTeam.push(name) : this.rightTeam.push(name)
                this.updateData(id)
                const playerIndex = this.chosablePlayers.findIndex(el => {
                    return el.nbr === id
                })
                this.chosablePlayers.splice(playerIndex, 1)
                if(this.firstChoice === true){
                    if(id === 1){
                        this.top1Pick()
                        this.firstChoice = false
                        this.top1First = true
                        setDoc(doc(db, "pickOrder", "firstPick"), {
                            top1First: true
                        })
                    } else {
                        this.top2Pick()
                        this.firstChoice = false
                        this.top1First = false
                        setDoc(doc(db, "pickOrder", "firstPick"), {
                            top1First: false
                        })
                    }
                } else {
                    if(this.top1First === true){
                        if(this.chosablePlayers.length === 7 && id >= 13 && id <= 16) this.lowPlayerMini = true
                        this.top1Pick()
                    } else {
                        if(this.chosablePlayers.length === 7 && id >= 13 && id <= 16) this.lowPlayerMini = true
                        this.top2Pick()
                    }
                }
            },
            async updateData(id){
                const theID = id.toString()
                await updateDoc(doc(db, "joueurs", theID), {
                    chosen: true,
                    leftTeam: this.isLeftTeamTurn
                })
            },
            top1Pick(){
                switch (this.chosablePlayers.length){
                    case 0:
                        this.finishedTeam = true
                    break;
                    case 1:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 2:
                        this.scriptPick(3,16)
                        if(this.rightTeam.length<9) this.isLeftTeamTurn = false
                    break;
                    case 3:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = false
                    break;
                    case 4:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 5:
                        this.scriptPick(3,16)
                        if(this.rightTeam.length<7) this.isLeftTeamTurn = false
                    break;
                    case 6:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = false
                    break;
                    case 7:
                        this.scriptPick(3,16)
                    break;
                    case 8:
                        this.scriptPick(13,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 9:
                        this.scriptPick(8,16)
                    break;
                    case 10:
                        this.scriptPick(3,12)
                        this.isLeftTeamTurn = false
                    break;
                    case 11:
                        this.scriptPick(3,12)
                        this.isLeftTeamTurn = true
                    break;
                    case 12:
                        this.scriptPick(3,8)
                        if(this.rightTeam.length <3) this.isLeftTeamTurn = false
                    break;
                    case 13:
                        this.scriptPick(2,2)
                        this.isLeftTeamTurn = false
                    break;
                    case 14:
                        this.scriptPick(13,16)
                    break;
                    case 15:
                        this.scriptPick(3,8)
                    break;
                }
            },
            top2Pick(){
                switch (this.chosablePlayers.length){
                    case 0:
                        this.finishedTeam = true
                    break;
                    case 1:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = false
                    break;
                    case 2:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 3:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = false
                    break;
                    case 4:
                        this.scriptPick(3,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 5:
                        this.scriptPick(8,16)
                        if(this.rightTeam.length<7) this.isLeftTeamTurn=false
                    break;
                    case 6:
                        this.scriptPick(3,12)
                        this.isLeftTeamTurn = false
                    break;
                    case 7:
                        this.scriptPick(3,16)
                    break;
                    case 8:
                        this.scriptPick(13,16)
                        this.isLeftTeamTurn = true
                    break;
                    case 9:
                        this.scriptPick(3,12)
                        this.isLeftTeamTurn = false
                    break;
                    case 10:
                        this.scriptPick(8,16)
                    break;
                    case 11:
                        this.scriptPick(3,12)
                        this.isLeftTeamTurn = true
                    break;
                    case 12:
                        this.scriptPick(13,16)
                        if(this.rightTeam.length<4) this.isLeftTeamTurn=false
                    break;
                    case 13:
                        this.scriptPick(3,8)
                        if(this.rightTeam.length<3) this.isLeftTeamTurn=false
                    break;
                    case 14:
                        this.scriptPick(1,1)
                        this.isLeftTeamTurn = false
                    break;
                    case 15:
                        this.scriptPick(3,8)
                    break;
                }
            },
            scriptPick(a,b){
                for(let i=0; i<this.players.length; i++){
                    if(this.players[i].nbr >= a && this.players[i].nbr <= b){
                        this.buttonsDisable[i] = false
                    } else {
                        this.buttonsDisable[i] = true
                    }
                }
            },
            disableButton(nbr) {
                const playerNbr = this.chosablePlayers.findIndex(el => {
                    return el.nbr === nbr
                })
                if(playerNbr === -1){
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>