<template>
    <div class="flex flex-col p-4 text-white z-10">
        <p v-for="player in rightTeam" :key="player" class="text-xl my-6 font-bold">{{ player }}</p>
    </div>
</template>

<script>
    export default{
        name: 'right-side',
        props: {
            rightTeam: {
                type: Array
            }
        }
    }
</script>