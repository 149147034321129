<template>
  <div>
    <MiddlePicker />
  </div>
</template>

<script>
// @ is an alias to /src
import MiddlePicker from '@/components/MiddlePicker.vue'

export default {
  name: 'HomeView',
  components: {
    MiddlePicker
  }
}
</script>
